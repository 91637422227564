const dataChevronRight = {
  name: 'IconChevronRight',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M10 12a1 1 0 0 1-.316.73l-8 7.5-1.368-1.46L7.538 12 .316 5.23l1.368-1.46 8 7.5A1 1 0 0 1 10 12z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 10 24'
  },
  width: 10,
  height: 24
}
export default dataChevronRight
