const dataCalendar = {
  name: 'IconCalendar',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M9 1.5H7V3h-.5a5 5 0 0 0-5 5v9.4a5 5 0 0 0 5 5h11a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5H17V1.5h-2V3H9V1.5zM15 5H9v1.5H7V5h-.5a3 3 0 0 0-3 3v.5h17V8a3 3 0 0 0-3-3H17v1.5h-2V5zm5.5 5.5h-17v6.9a3 3 0 0 0 3 3h11a3 3 0 0 0 3-3v-6.9z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataCalendar
