const dataExclamationCircleSolid = {
  name: 'IconExclamationCircleSolid',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M1 12c0 6.075 4.925 11 11 11s11-4.925 11-11S18.075 1 12 1 1 5.925 1 12zm10-5.4v7h2v-7h-2zm2.25 9.55a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataExclamationCircleSolid
