const dataChevronDoubleRight = {
  name: 'IconChevronDoubleRight',
  content: '<path fill="currentColor" d="M19.707 12.707a1 1 0 0 0 0-1.414l-7-7-1.414 1.414L17.586 12l-6.293 6.293 1.414 1.414 7-7z"/><path fill="currentColor" d="m5.707 19.707 7-7a1 1 0 0 0 0-1.414l-7-7-1.414 1.414L10.586 12l-6.293 6.293 1.414 1.414z"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataChevronDoubleRight
