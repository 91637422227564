const dataChevronUp = {
  name: 'IconChevronUp',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M12.25 7.25a1 1 0 0 1 .73.316l7.5 8-1.46 1.368-6.77-7.222-6.77 7.222-1.46-1.368 7.5-8a1 1 0 0 1 .73-.316z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataChevronUp
