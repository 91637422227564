const dataMinus = {
  name: 'IconMinus',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M21.3 13.2H2.8v-2.4h18.5v2.4z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataMinus
