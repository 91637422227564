const dataChevronDoubleLeft = {
  name: 'IconChevronDoubleLeft',
  content: '<path fill="currentColor" d="M4.293 11.293a1 1 0 0 0 0 1.414l7 7 1.414-1.414L6.414 12l6.293-6.293-1.414-1.414-7 7z"/><path fill="currentColor" d="m18.293 4.293-7 7a1 1 0 0 0 0 1.414l7 7 1.414-1.414L13.414 12l6.293-6.293-1.414-1.414z"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataChevronDoubleLeft
