const dataCheckCircleSolid = {
  name: 'IconCheckCircleSolid',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.229-5.578 7.14-7.219-1.422-1.406-5.663 5.726-2.89-3.15-1.473 1.351 4.308 4.698z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataCheckCircleSolid
