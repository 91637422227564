const dataPlus = {
  name: 'IconPlus',
  content: '<path fill="currentColor" d="M10.8 12.9v8.8h2.4v-8.8h8.5v-2.4h-8.5V2.2h-2.4v8.3H2.2v2.4h8.6z"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataPlus
