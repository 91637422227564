const dataChevronDown = {
  name: 'IconChevronDown',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M13.709 16.406a2 2 0 0 1-2.918 0L4.02 9.184l1.458-1.368 6.771 7.222 6.77-7.222 1.46 1.368-6.771 7.222z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataChevronDown
