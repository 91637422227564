const dataInfoCircleSolid = {
  name: 'IconInfoCircleSolid',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm12.25-4.15a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM11 17.4v-7h2v7h-2z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataInfoCircleSolid
