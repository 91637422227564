const dataAsterisk = {
  name: 'IconAsterisk',
  content: '<path fill="currentColor" d="M13.34 6h-2.666l.505 4.667L6.69 8.88 6 11.457l4.743.693-3.123 4.191 2.31 1.334L12 12.87l2.07 4.805 2.31-1.334-3.123-4.19L18 11.456l-.69-2.576-4.474 1.78.505-4.66z"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataAsterisk
