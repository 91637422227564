const dataTimesCircleSolid = {
  name: 'IconTimesCircleSolid',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM7.086 8.5 8.5 7.086l3.5 3.5 3.5-3.5L16.914 8.5l-3.5 3.5 3.5 3.5-1.414 1.414-3.5-3.5-3.5 3.5L7.086 15.5l3.5-3.5-3.5-3.5z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataTimesCircleSolid
