const dataQuestionCircleSolid = {
  name: 'IconQuestionCircleSolid',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zm0-17a3.7 3.7 0 0 0-3.7 3.7h2a1.7 1.7 0 1 1 2.737 1.347l-.009.006-.072.052-.01.008a1.82 1.82 0 0 1-.081.051l.004.007a5.739 5.739 0 0 0-.818.78C11.57 12.513 11 13.413 11 14.56h2c0-.472.24-.922.57-1.307a3.722 3.722 0 0 1 .543-.515A3.69 3.69 0 0 0 15.7 9.7 3.7 3.7 0 0 0 12 6zm.054 12.34a1.242 1.242 0 1 0 0-2.484 1.242 1.242 0 0 0 0 2.484z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataQuestionCircleSolid
