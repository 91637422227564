const dataChevronLeft = {
  name: 'IconChevronLeft',
  content: '<path fill="currentColor" fill-rule="evenodd" d="m2.462 12 7.222-6.77-1.368-1.46-8 7.5a1 1 0 0 0 0 1.46l8 7.5 1.368-1.46L2.462 12z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 10 24'
  },
  width: 10,
  height: 24
}
export default dataChevronLeft
