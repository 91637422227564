const dataQuestionCircle = {
  name: 'IconQuestionCircle',
  content: '<path fill="currentColor" d="M12.054 18.34a1.242 1.242 0 1 0 0-2.484 1.242 1.242 0 0 0 0 2.484zM8.3 9.7a3.7 3.7 0 1 1 5.813 3.038c-.029.021-.064.05-.106.084-.12.1-.28.247-.438.431-.33.385-.569.835-.569 1.307h-2c0-1.148.57-2.048 1.05-2.608a5.739 5.739 0 0 1 .819-.78l-.004-.008a1.82 1.82 0 0 0 .08-.05l.011-.009.072-.052.009-.006A1.69 1.69 0 0 0 13.7 9.7a1.7 1.7 0 1 0-3.4 0h-2z"/><path fill="currentColor" fill-rule="evenodd" d="M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zm-2 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataQuestionCircle
