const dataTimes = {
  name: 'IconTimes',
  content: '<path fill="currentColor" d="m7.444 11.998-7 7 1.555 1.556 7-7 7 7L17.556 19l-7-7 7-7.001L16 3.442l-7 7-7.001-7L.443 4.998l7 7z"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 18 24'
  },
  width: 18,
  height: 24
}
export default dataTimes
