const dataTimesCircle = {
  name: 'IconTimesCircle',
  content: '<path fill="currentColor" d="m8.5 7.086 3.5 3.5 3.5-3.5L16.914 8.5l-3.5 3.5 3.5 3.5-1.414 1.414-3.5-3.5-3.5 3.5L7.086 15.5l3.5-3.5-3.5-3.5L8.5 7.086z"/><path fill="currentColor" fill-rule="evenodd" d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataTimesCircle
