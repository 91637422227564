const dataExclamationTriangleSolid = {
  name: 'IconExclamationTriangleSolid',
  content: '<path fill="currentColor" fill-rule="evenodd" d="M14.598 3.298c-1.155-2-4.041-2-5.196 0L1.2 17.5c-1.155 2 .289 4.5 2.598 4.5h16.406c2.31 0 3.753-2.5 2.598-4.5L14.598 3.298zM11 7.4v8h2v-8h-2zm2.25 10.55a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0z" clip-rule="evenodd"/>',
  attributes: {
    xmlns: 'http://www.w3.org/2000/svg',
    fill: 'none',
    viewBox: '0 0 24 24'
  },
  width: 24,
  height: 24
}
export default dataExclamationTriangleSolid
